export default {
  defaultTheme: "chess",
  themes: {
    chess: {
      dark: true,
      colors: {
        activeMistake:"rgb(30, 30, 30)",
        bg:"#171717",
        darkGrey: "#171717",
        veryDarkGrey: "#3C3C3C",
        form:"#232323",
        "input-field":"#1E1E1E",
        "tree-field":"#3F3F3F",
        "form-stroke":"#3C3C3C",
        primary:"#1D85DD",
        "primary-hover":"#00B1E9",
        "primary-disabled":"#2B2C31",
        "lightGrey":"#9A9A9A",
        "text-secondary":"#9A9A9A",
        "text-primary":"#F4F4F4",
        "button-secondary":"#4A4A4A",
        "button-hover":"#52545A",
        "button-loading":"#797C84",
        white:"#FFFFFF",
        green: "#06D295",
        red: "#DD6464",
        violet: "#823EF2",
        "violet-hover": "#9C24D4",
        yellow:"#F4BA24",
        // primary: "#b6e0f2",
        // "primary-light-2": "#eae4ee",
        // "white-accent": "#fcfcfc",
        // green: "#51cc00",
        // "light-green": "#e3ffbc",
        // orange: "#ff9100",
        // red: "#fd0000",
        // blue: "#008cee",
        // "light-blue": "#bce3ff",
        // grey: "#c0c0c0",
        "light-grey": "#f1f1f1",
        // darkGrey: "#898989",
        // yellow: "#d5c200",
        // black: "#3f4948",
        // "light-purple": "#b989ff",
        // white: "ffffff"
      },
    },
  },
};
