import { computed } from 'vue';
import { useStore } from 'vuex';

export const useUIStore = () => {
  const store = useStore();

  const uiDispatch = (actionName: string, payload: Record<string, unknown>): Promise<void> => store.dispatch(`ui/${actionName}`, payload);
  const uiState = computed(() => store.state.ui);
  const uiCommit = (mutationName: string, payload: Record<string, unknown>): void => {
    store.commit(`ui/${mutationName}`, payload);
  };
  return {
    uiDispatch,
    uiState,
    uiCommit,
  };
}