// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import * as components from "vuetify/components";
import { mdi, aliases } from "vuetify/iconsets/mdi";
import theme from "@/constants/theme";
// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: theme,
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
    },
    aliases,
  },
  components,
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
