const parseArray = (str) => {
    if (!str) return [];
  
    const replaced = str.replace("{", "[").replace("}", "]");
  
    return JSON.parse(replaced);
  }

export default  (obj) => {
    return {
      compare_pgid: obj.compare_pgid, 
      compare_tc: typeof obj.compare_tc === 'string' ? parseArray(obj.compare_tc) :  obj.compare_tc, 
      compare_elo: typeof obj.compare_elo === 'string' ? parseArray(obj.compare_elo) :  obj.compare_elo, 
      compare_ed: obj.compare_ed?.indexOf("{") > -1 ? parseArray(obj.compare_ed) : obj.compare_ed
    }
}