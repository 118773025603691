import Vuex from "vuex";
import axios from '@/plugins/axiosWrapper'
import uiModule from "./ui";
import dataModule from "./data";
import authModule from "./auth";
import { useLastPage } from "@/shared/composables/useLastPage";

const isProdMode = process.env.VUE_APP_PROD_MODE;
const AUTH_URL = process.env.VUE_APP_AUTH_API;
const API_URL = process.env.VUE_APP_DEV_API;

const BASE_URL = isProdMode ? AUTH_URL : API_URL;

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    token: "",
    sizes: {vh: 0, vw: 0}
  },
  mutations: {
    "SET_DATA_BY_KEY": (state, {key, value, }) => state[key] = value 
  },
  actions: {
    authUser: async ({ commit, dispatch}, {email, password, remember, callBack, language}) => {

      const mtd = isProdMode ? 'user_login' : 'signin';
     
      const resp = await axios({
        method: 'post',
        url: `${BASE_URL}/api/`,
        data: {
          email,
          password,
          mtd,
          remember_me: remember ? 1 : 0,
          language,
        },


        headers: { "Content-Type": "application/json; charset=utf-8",'x-forwarded-for': localStorage.getItem('ip-from') },

      }).catch((e) => console.log(e))

      const tokenKey = isProdMode ? 'access_token' : 'token';

      if(resp && resp.data?.[tokenKey]) {
        localStorage.setItem('token', resp.data[tokenKey]);
        commit("SET_DATA_BY_KEY", {key: 'isAuthenticated', value: true});
        // dispatch('ui/getSettingsAndPreferences');
        callBack();
      } else {
        return resp
      }
   
    },
    // authUserByGoogle: async () => {
    //   return axios({
    //     // headers: { 'x-forwarded-for': localStorage.getItem('ip-from')  },
    //     method: "GET",
    //     url: `${AUTH_URL}/api/google-login`,
    //     data: {},
    //   }).catch((e) => console.log(e));
    // },
    logOutUser: async ({ commit }, callBack) => { 
      const token = localStorage.getItem("token");
      const mtd = isProdMode ? 'logout' : 'signout';
      await axios({
        method: 'post',
        url: `${BASE_URL}/api/`,
        data: { mtd },
 
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },

      }).catch((e) => console.log(e))
      localStorage.removeItem('token');
      useLastPage().removeLastPage();
      

      commit("SET_DATA_BY_KEY", {key: 'isAuthenticated', value: false})
      commit("ui/RESET")
      commit("data/RESET")


      callBack()
    }
  },
  modules: {
    auth: authModule,
    ui: uiModule,
    data: dataModule,
  },
  getters: {},
});
