import axios from 'axios';
import router from '@/router/router';
import orch from './requestOrchestrator';
import store from '@/store';


const api = axios.create();
api.interceptors.response.use(
  (response) => {
    // console.log('response', response);
    return response;
  },
  (error) => {
    console.error('error', error);
    //тут обработка для ческома
    if (error?.config?.url?.indexOf('api.chess.com') > -1) {
      if (error?.response?.status === 400) {
        return { errorMessage: error?.response?.data?.message };
      }
      if (error?.code === 'ERR_NETWORK') {
        return { errorMessage: 'User not found' };
      }
    }

    if (error?.config?.url?.indexOf('lichess.org') > -1) {
      if (error?.response?.status === 404) {
        return { errorMessage: 'User not found' };
      }
    }

    if (
      error?.response?.status === 401 ||
      error?.response?.data?.message === 'Failed to get userid by token' ||  
      error?.response?.data?.message === 'Forbidden' ||
      error?.response?.data?.message === 'Unauthorized' ||
      error?.response?.data?.message === 'Unauthorized (token expired)' ||
      error?.response?.data?.message === 'Method not allowed'
    ) {
      orch.killAll()
      localStorage.removeItem('token');
      store.commit("SET_DATA_BY_KEY", { key: 'isAuthenticated', value: false });
      router.push({ name: 'Login' });
    }

    return error?.response?.data;

    // whatever you want to do with the error
    // return { errorMessage: error?.response?.data?.message || 'Some problem' };
  },
);



export default api;
