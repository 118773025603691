<template>
  <v-table
    class="sc-table"
    :fixed-header="fixedHeader"
    :height="height"
  >
    <thead>
      <tr>
        <th class="text-left semibold-14" v-for="(header, index) in headers" :key="index" style="color: #F4F4F4">
          {{ header.title }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        :class="{ 'sc-table__row--selected': row.selected }"
        v-for="(row, index) in rows"
        :key="index"
        @click="onClick(row)"
      >
        <td class="semibold-14" v-for="(cell, index) in headers" :key="index">{{ row[headers[index].value] }}</td>
      </tr>
    </tbody>
  </v-table>
</template>

<script setup lang="ts">

//пока перенёс стили в компонент, т.к. npm run serve внезапно стало ругаться
import { ScTableProps, TableRow } from '@/components/Common/ScTable/ScTable.types';
// export interface TableHeader {
//   value: string;
//   title: string;
// }
// export type TableRow = Record<string, unknown>;

// export interface ScTableProps {
//   rows: TableRow[];
//   headers: TableHeader[];
//   height: string | number;
//   fixedHeader: boolean;
//   clickable?: boolean;
// }
const props = defineProps<ScTableProps>();

const emit = defineEmits(['clickOnRow']);

const onClick = (row: TableRow) => {
  if (!props.clickable) {
    return;
  }
  emit('clickOnRow', row);
};
</script>

<style scoped>
.sc-table__row--selected td {
    background-color: #2B2C31;
    color: #9A9A9A;
}
</style>