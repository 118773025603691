import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './routeNames';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    // redirect: () => ({ name: RouteNames.CARDS }),
    children: [
      {
        path: '',
        component: () => import('@/pages/CardsPage.vue'),
        name: RouteNames.CARDS
      },
      {
        path: '/about',
        component: () => import('@/pages/AboutPage.vue'),
        name: RouteNames.ABOUT
      },
      {
        path: '/pre-analysis',
        component: () => import('@/pages/PreAnalysisPage.vue'),
        name: RouteNames.PRE_ANALYSIS
      },
      {
        path: '/analysis',
        component: () => import('@/pages/AnalysisPage/AnalysisPage.vue'),
        name: RouteNames.ANALYSIS
      },
      {
        path: '/exercise',
        component: () => import('@/pages/ExercisePage/ExercisePage.vue'),
        name: RouteNames.EXERCISE_PAGE
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/layouts/LoginLayout.vue'),
    children: [
          {
            path: '',
            component: () => import('@/components/LoginPage/LoginComponent.vue'),
            name: RouteNames.LOGIN,
          },
          {
            path: '/register',
            component: () => import('@/components/LoginPage/RegisterComponent.vue'),
            name: RouteNames.REGISTER,
          },
          {
            path: '/recovery',
            component: () => import('@/components/LoginPage/RecoveryComponent.vue'),
            name: RouteNames.RECOVERY,
          },
          {
            path: '/change-password',
            component: () => import('@/components/LoginPage/ChangePasswordComponent.vue'),
            name: RouteNames.CHANGE_PASSWORD,
          },
          {
            path: '/confirmemail',
            component: () => import('@/pages/ConfirmEmailPage.vue'),
            name: RouteNames.EMAIL
          },
          {
            path: '/waiting-for-confirmation',
            component: () => import('@/components/LoginPage/WaitingForConfirmationComponent.vue'),
            name: RouteNames.WAITING_FOR_CONFIRMATION,
          },
          {
            path: '/promocode', // НЕ МЕНЯТЬ!!! используется для редиректа в емейле
            component: () => import('@/components/LoginPage/PromocodeFormComponent.vue'),
            name: RouteNames.PROMOCODE_FORM
          },
          {
            path: '/promocode-success',
            component: () => import('@/components/LoginPage/PromocodeSuccessComponent.vue'),
            name: RouteNames.PROMOCODE_SUCCESS,
          },
          {
            path: '/registration-success',
            component: () => import('@/components/LoginPage/RegistrationSuccessComponent.vue'),
            name: RouteNames.REGISTRATION_SUCCESS,
          },
          {
            path: '',
            component: () => import('@/components/LoginPage/AfterLoginComponent.vue'),
            name: RouteNames.AFTER_LOGIN,
          },
    ]
  },
  {
    path: '/dev',
    component: () => import('@/pages/DevelopPage.vue'),
    name: RouteNames.DEVELOP_PAGE
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/pages/NotFoundPage.vue'),
    name: RouteNames.NOT_FOUND
  }
];