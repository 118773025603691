import { computed } from 'vue';
import { useStore } from 'vuex';

export const useDataStore = () => {
  const store = useStore();

  const dispatch = (actionName: string, payload: Record<string, unknown>): Promise<void> => store.dispatch(`data/${actionName}`, payload);
  const state = computed(() => store.state.data);
  const commit = (mutationName: string, payload: Record<string, unknown>): void => {
    store.commit(`data/${mutationName}`, payload);
  };
  return {
    dispatch,
    state,
    commit,
  };
}